import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";
import Button from "../../../components/button/Button";
import "./ProjectDataCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { removeProjectSiteRoute } from "../../../store/actions/projects/projects.action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SITE_ROUTE_NOTES_SUCCESS } from "../../../store/actionTypes";
import useIsMobile from "../../../utils/helpers/use-is-mobile";
dayjs.extend(relativeTime);

const ProjectDataCard = ({
  id,
  name,
  address,
  city,
  status,
  state,
  units,
  account_name,
  state_short,
  account_billing_address,
  sf_size,
  url_slug,
  isFavourite,
  handleArchive,
  handleFavourite,
  handleProjectDetailCLick,
  est_break_ground_date,
  project_completion,
  site_contact_name,
  site_contact_phone,
  primary_contact_name,
  primary_contact_phone,
  isHidden,
  count
}) => {
  const { siteRoutesNotes } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const handleProjectStatusUpdate = (e) => {
    if (status === "NA") {
      handleArchive(e, id);
    } else {
      handleArchive(e, id);
    }
  };

  const removeSiteRouteProject = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(removeProjectSiteRoute(id))
  };

  const handleUpdateNote = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { value } = e.target;
    const notes = {
      ...siteRoutesNotes,
      [id]: value
    }
    dispatch({
      type: SITE_ROUTE_NOTES_SUCCESS,
      notes
    })
  };

  const formatDate = (dateString) => {
    if (!dateString)
      return null;
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    return formattedDate;
  };

  const openSiteVisitWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const project_address = (address || address != null ? address : '') + (city ? ", " + city : "") + (state ? ", " + state : "");
    const url = `/site_visit?project_name=${encodeURIComponent(
      name
    )}&project_address=${encodeURIComponent(
      project_address
    )}&company=${encodeURIComponent(
      account_name != null ? account_name : ""
    )}&superintendent=${encodeURIComponent(
      (site_contact_name != null ? site_contact_name : "") +
        (site_contact_phone
          ? ` (${site_contact_phone.replaceAll("(", "").replaceAll(")", "")})`
          : "") || ""
    )}&project_manager=${encodeURIComponent(
      primary_contact_name +
        (primary_contact_phone
          ? ` (${primary_contact_phone
              .replaceAll("(", "")
              .replaceAll(")", "")})`
          : "") || ""
    )}&square_foot=${encodeURIComponent(sf_size || "")}&units=${
      units || ""
    }&disabled=true`;

    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    window.open(url, "_blank", windowFeatures);
  };

  return (
    <div className="d-flex flex-column board-list-card bg-white py-4 my-2">
      <Link
        to={`/project_board/${url_slug}`}
        className="text-decoration-none text-reset flex-grow-1"
        onClick={(e) =>
          handleProjectDetailCLick(e, `/project_board/${url_slug}`)
        }
      >
        <div className="d-flex justify-content-between">
          <div className="text-start flex-grow-1">
            <div className="d-flex justify-content-between mb-3">
              <div className="flex-grow-1 fw-bolder text-start text-13 project-name">
                {name}
              </div>
              <div className="flex-grow-1 text-start">
                {account_name}
              </div>

              <div className="d-flex justify-content-between">
                <div onClick={handleProjectStatusUpdate}>{status === 'A' ? "Hide" : "Unhide"}</div>
                {!isHidden && (
                  <div className="svg-container">
                    {isFavourite ? (
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="cursor-pointer mx-2"
                        size="xl"
                        style={{ color: "#0d6efd" }}
                        onClick={(e) => handleFavourite(e, "unfavourite", id)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="cursor-pointer mx-2"
                        size="xl"
                        style={{
                          color: "#fff",
                          stroke: "#0d6efd",
                          strokeWidth: 50,
                        }}
                        onClick={(e) => handleFavourite(e, "favourite", id)}
                      />
                    )}
                  </div>
                )}
                <div className="svg-container">
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="cursor-pointer"
                    size="xl"
                    style={{
                      color: "black",
                      stroke: "white",
                      strokeWidth: 15,
                    }}
                    onClick={removeSiteRouteProject}
                  />
                </div>
              </div>
            </div>
            {isMobile ? (
              <>
                <div className="d-flex justify-content-between mb-3">
                  <div className="flex-grow-1 m-1">
                    {address} {city},{state_short}
                  </div>
                  <div className="m-1">Sqft.<br/>{sf_size === 0 ? sf_size : '-'}</div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  {formatDate(est_break_ground_date) &&
                    <div className="flex-grow-1 m-1">
                      Break ground Date <br></br>{formatDate(est_break_ground_date)}
                    </div> }
                  {formatDate(project_completion) &&
                    <div className="m-1">
                      Completion Date <br></br>{formatDate(project_completion)}
                    </div> }
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between mb-3">
                <div className={`flex-grow-1 ${address && 'm-1'}`}>
                  {address} {city},{state_short}
                </div>
                <div className="flex-grow-1 m-1">Sqft.<br/>{sf_size !== 0 ? sf_size : '-'}</div>
                {formatDate(est_break_ground_date) &&
                  <div className="flex-grow-1 m-1">
                    Break ground Date <br></br>{formatDate(est_break_ground_date)}
                  </div> }
                {formatDate(project_completion) &&
                  <div className="flex-grow-1 m-1">
                    Completion Date <br></br>{formatDate(project_completion)}
                  </div> }
              </div>
            )}

          </div>
        </div>
      </Link>
      <div className={`d-flex justify-content-between mt-2`}>
        <div className="flex-grow-1">
          <textarea
            className="w-100 rounded-6 textarea-notes"
            rows={1}
            value={siteRoutesNotes? siteRoutesNotes[id] || "" : ''}
            onChange={handleUpdateNote}
            // onBlur={handleUpdateAllNotes}
            >
          </textarea>
        </div>
        <div style={{ marginLeft: '5px', alignSelf: 'center' }}>
          <Button
            className="p-3 btn btn-light"
            style={{ backgroundColor: "#ebebeb" }}
            onClick={openSiteVisitWindow}
          >
            <span className="h4">Create Site Visit Sheet</span>
          </Button>
        </div>
      </div>
      <p className="text-start" style={{ marginLeft: '-10px', marginTop: '-5px' }}>{count+1}</p>
    </div>
  );
};

ProjectDataCard.defaultProps = {
  name: "Williams Square (Ameilia House & Hotel Fredericksburg...",
  address: "Fredericksburg, VA",
  city: "city",
  state_short: "IA",
  created_date: "2020-04-20T16:08:10-04:00",
  account_name: "Damilola Adekoya",
  sf_size: 54000,
  id: 1,
  isHidden: false,
};

ProjectDataCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default ProjectDataCard;
