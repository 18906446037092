import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Navbar,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { isSubscriptionActive, toastError } from "../../utils/helpers/helper";
import Spinner from "../../components/spinner/Spinner";
import MultiStateSelection from "../../components/MultiStateSelection";
import { getStateLaborPricingList } from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import "./index.css";
import SubscriptionModal from "../../components/subscriptionModal";
import LoginModal from "../../components/LoginModal";
import {
  addUserContactEmail,
  deleteUserCompany,
  deleteUserContact,
  downloadContactsList,
  getAllContacts,
} from "../../store/actions/contacts/contacts.actions";
import SendContactEmailContainer from "./components/SendContactEmailContainer";
import { listEmailTemplates } from "../../store/actions/emailTemplates/emailTemplate.actions";
import CheckBoxContainer from "./components/CheckBoxContainer";
import CountUp from "react-countup";
import Button from "react-bootstrap/Button";
import Modal from "../../components/modal/Modal";
import SendContactMassEmailForm from "./components/SendContactMassEmailForm";
import { emailTemplate } from "../../store/selectors/projects/project.selector";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
  setFreeModeAction,
} from "../../store/actions/users/users.actions";
import { USER_SET_FREE_MODE_SUCCESS } from "../../store/constants/userConstants";
import ContactOutreachIcon from "../../assets/img/contactOutreachIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faStar as solidStar,
} from "@fortawesome/free-solid-svg-icons";
import PhoneLogContainer from "./components/PhoneLogContainer";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import { downloadListReducer } from "../../store/reducers/contact/contactReducer";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import moment from "moment";
import EmailModal from "../../components/emailModal";
import { addContactActivity, updateContactLastDateByName } from "../../store/actions/projects/projects.action";

const StyledSwitch = styled(Form.Check)`
  .custom-toggle .form-check-input {
    // Increase the width to accommodate the text "Available" / "Not Available"
    width: 120px; // Adjust this width based on your actual needs
    height: 1.5rem; // Also adjust the height if necessary
    font-weight: bold;
    font-size: 0.7em;
    &:checked {
      background-color: #0d6efd; // Bootstrap primary color or any color you like
      &:after {
        content: "Estimating";
        color: white; // Text color when switch is active
        padding-left: 32px; // Adjust the padding to position the text correctly
      }
    }
    &:not(:checked) {
      background-color: #0d6efd; // A gray color for the off state
      &:after {
        content: "C-Level";
        color: white; // Text color when switch is inactive
        padding-left: 25px; // Adjust the padding to position the text correctly
      }
    }
    // This will move the toggle's indicator to the opposite side
    &:checked:before {
      right: -20px; // Adjust this value as needed
    }
    &:not(:checked):before {
      left: -20px; // Adjust this value as needed
    }
  }
`;

const ContactOutreach = () => {
  const [contact, setContact] = useState("");
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile } = useSelector((state) => state.userDetails);
  let {
    contacts,
    loading,
    count,
    error,
    total_email_sent,
    recent_emails,
    recent_downloads,
  } = useSelector((state) => state.contacts);
  let { emailTemplates } = useSelector((state) => state.emailTemplates);
  const { stateLabors } = useSelector((state) => state.stateLaborPricingList);
  const [phaseFilter, setPhaseFilter] = useState("active");
  const [selectedState, setSelectedState] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isMainCheckboxChecked, setMainCheckboxChecked] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [sortState, setSortState] = useState({
    prop: "last_modified_date",
    order: "desc",
  });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const emailTemplateBody = useSelector(emailTemplate());
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const [estimatingStatus, setEstimatingStatus] = useState(true);

  const {
    message,
    error: downloadError,
    loading: downLoading,
  } = useSelector((state) => state.downloadContacts);

  const { hasFeatureAccess, enforceAccess } =
    useFeatureAccess("Contact Outreach");

  const price_id = process.env.REACT_APP_CONTACT_OUT_REACH;

  const handleMainCheckboxChange = () => {
    setMainCheckboxChecked((prevChecked) => !prevChecked);
    setSelectedContacts(
      !isMainCheckboxChecked
        ? contacts
            .filter((contact, index) => index + 1 + total_email_sent <= 50)
            .map((contact) => contact.contact_id)
        : []
    );
  };

  const handleSetFreeMode = () => {
    dispatch(setFreeModeAction());
    dispatch({
      type: USER_SET_FREE_MODE_SUCCESS,
      payload: free_mode_count + 1,
    });
  };

  const handleContactSelection = (contactId) => {
    setSelectedContacts((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(contactId);
      if (isAlreadySelected) {
        return prevSelected.filter((id) => id !== contactId);
      } else {
        if (total_email_sent + selectedContacts.length < 50) {
          return [...prevSelected, contactId];
        } else {
          toastError("You can only select 50 contacts daily ");
          return [...prevSelected];
        }
      }
    });
  };

  const fetchContactAfterEmail = () => {
    if (localStorage.getItem("locations")) {
      let states = localStorage.getItem("locations").split(",");

      setSelectedState(states.map((state) => ({ label: state, value: state })));
      dispatch(getAllContacts(`location=${states.join(",")}`));
    } else {
      dispatch(getAllContacts());
    }
  };

  const processAction = (e, link) => {
    window.open(link, "_blank");
  };

  const handleDeleteCompany = (id) => {
    dispatch(deleteUserCompany(id));
  };

  const handleDeleteContact = (id) => {
    dispatch(deleteUserContact(id));
  };

  const handleOpenEmail = (contact) => {
    if (total_email_sent >= 50) {
      toastError("You can only contact to 50 contacts daily");
      return;
    }
    setEmailModalShow(true);
    setContact({
      id: contact.contact_id,
      name: contact.name,
      email: contact.email,
    });
  };

  const formatDate = (date) => {
    if (!date) return " - ";
    const date_local = new Date(new Date(date).toLocaleDateString());
    const day =
      date_local.getDate() < 10
        ? `0${date_local.getDate()}`
        : date_local.getDate();
    const month =
      date_local.getMonth() < 9
        ? `0${date_local.getMonth() + 1}`
        : date_local.getMonth() + 1;
    return `${month}/${day}`;
  };

  const toLocalISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
  };

  const convertHtml = (html) => {
    return html
      ?.replaceAll("&nbsp;", " ")
      ?.replaceAll("<div><div>", "\n")
      ?.replaceAll("<div>", "")
      ?.replaceAll("</div><br>", "\n")
      ?.replaceAll("</div>", "\n")
      ?.replaceAll("<br>", "\n")
      ?.replaceAll("<br/>", "\n")
      ?.replaceAll("<br />", "\n")
      ?.replaceAll("&#39;", "'")
      ?.replaceAll("<p>", "")
      ?.replaceAll("</p>", "");
  };

  const handleSendEmail = async (appName, email_body) => {
    const recipient = contact?.email;
    const recipientName = contact.name;
    const greeting = `Hi ${recipientName.split(" ")[0]},`;

    const subject = `${profile?.company_name} request to bid upcoming projects`;

    const message = convertHtml(email_body);
    const body = `${greeting}\n\n${message}\n\n${
      profile?.proposal_point_contact_name
    }\n${profile?.proposal_point_contact_phone}\n${
      profile?.proposal_point_contact_email
    }\n\n${profile?.company_name && profile?.company_name + "\n"}${
      profile?.company_street && profile?.company_street + "\n"
    }${profile?.company_city ? profile?.company_city + ", " : ""}${
      profile?.company_state
    } ${profile?.company_zip}`;
    const simple_body = `${greeting}\n\n${message}\n\n`;
    // Mailto link for default mail client
    const defaultMailLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Gmail link
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Outlook link
    const outlookLink = `https://outlook.office.com/owa/?path=/mail/action/compose&to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    // Yahoo Mail link
    const yahooLink = `https://compose.mail.yahoo.com/?to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    switch (appName) {
      case "gmail":
        window.open(gmailLink, "_blank");
        break;
      case "yahoo":
        window.open(yahooLink, "_blank");
        break;
      case "outlook":
        window.open(outlookLink, "_blank");
        break;
      case "default":
        window.open(defaultMailLink, "_blank");
        break;
      default:
        window.open(defaultMailLink, "_blank");
        break;
    }
    let saved = await dispatch(addUserContactEmail(contact.id, user?.id));
    saved && handleLoadContactByEvent();
    const today = new Date().toISOString();
    dispatch(updateContactLastDateByName(today.slice(0, 16), recipient, appName == "outlook" || appName == "yahoo" ? simple_body : body));
  };

  contacts?.forEach((item, index) => {
    item.serialNumber = index + 1;
  });
  // Create table headers consisting of 4 columns.
  const STORY_HEADERS = [
    {
      prop: "serialNumber",
    },
    {
      prop: "name",
      title: (
        <Form.Group
          as={Col}
          md={12}
          className="checkbox m-0 d-flex col-md-12"
          controlId="parentCheckBox"
        >
          <Form.Check
            type="checkbox"
            name="selectAll"
            label={
              selectedContacts.length > 0
                ? `${selectedContacts.length} selected contacts `
                : `selected contacts`
            }
            className=" fw-bolder ms-0"
            checked={isMainCheckboxChecked}
            onChange={handleMainCheckboxChange}
          />
        </Form.Group>
      ),
      cell: (row) => (
        <CheckBoxContainer
          key={row.id}
          row={row}
          isMainCheckboxChecked={isMainCheckboxChecked}
          selectedContacts={selectedContacts}
          onContactSelection={handleContactSelection}
        />
      ),
    },
    {
      prop: "name",
      title: "Name",
      isSortable: true,
    },
    {
      prop: "last_contact_date",
      title: "Last Contact",
      isSortable: true,
      cell: (row) => <div>{formatDate(row?.last_contact_date)}</div>,
    },
    {
      prop: "title",
      title: "Title",
      isSortable: true,
    },
    {
      prop: "company_account_name",
      title: "Account Name",
      isSortable: true,
      cell: (row) => {
        return row.company_account_website ? (
          <a
            href={standardizeUrl(row.company_account_website)}
            className="text-decoration-none"
            target="_blank"
            rel="noreferrer"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="tooltip">
                  {row?.last_account_date
                    ? "Contact made " + moment.utc(row?.last_account_date).local().fromNow()
                    : "Never made contact"}
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>
                {row?.company_account_name}
              </span>
            </OverlayTrigger>
          </a>
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="tooltip">
                {row?.last_account_date
                  ? "Contact made " + moment.utc(row?.last_account_date).local().fromNow()
                  : "Never made contact"}
              </Tooltip>
            }
          >
            <span style={{ cursor: "pointer" }}>
              {row?.company_account_name}
            </span>
          </OverlayTrigger>
        );
      },
    },
    // {D
    //   prop: "last_activity",
    //   title: "Last Activity",
    //   isSortable: true,
    // },

    {
      prop: "stage_name",
      title: "Action",
      isSortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          {/*<SendContactEmailContainer*/}
          {/*  key={row.id}*/}
          {/*  row={row}*/}
          {/*  handleSetLoginModal={""}*/}
          {/*  handleSetFreeMode={""}*/}
          {/*  user={user}*/}
          {/*  free_mode_count={free_mode_count}*/}
          {/*  emailTemplates={emailTemplates}*/}
          {/*  fetchContactAfterEmail={fetchContactAfterEmail}*/}
          {/*  handleSetPaymentModal={handleSetPaymentModal}*/}
          {/*  price_id={price_id}*/}
          {/*/>*/}
          {row.email ? (
            <Button
              customClassName="btn-primary"
              style={{ fontSize: "12px", height: "auto" }}
              onClick={() => handleOpenEmail(row)}
            >
              Send Email
            </Button>
          ) : (
            <Button
              style={{
                fontSize: "12px",
                cursor: "auto",
                background: "transparent",
                border: "none",
                color: "transparent",
              }}
            >
              Send Email
            </Button>
          )}
          <PhoneLogContainer row={row} />
          <DropdownButton id="dropdown-item-button" title="">
            <Dropdown.Item
              as="button"
              onClick={(e) => handleDeleteCompany(row.company_id)}
            >
              Delete Company
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={(e) => handleDeleteContact(row.contact_id)}
            >
              Delete Contact
            </Dropdown.Item>
            {row.company_planroom_link && (
              <Dropdown.Item
                as="button"
                onClick={(e) => processAction(e, row.company_planroom_link)}
              >
                Visit Planroom
              </Dropdown.Item>
            )}
            {row.prequalification_application && (
              <Dropdown.Item
                as="button"
                onClick={(e) =>
                  processAction(e, row.prequalification_application)
                }
              >
                Visit Prequalification
              </Dropdown.Item>
            )}
            {/*<Dropdown.Item as="button">Something else</Dropdown.Item>*/}
          </DropdownButton>
        </div>
      ),
    },
    // {
    //   prop: "phone",
    // },
  ];

  const path = window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = "Contact Outreach";
    const type = "Contact Outreach";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, path]);
  useEffect(() => {
    if (localStorage.getItem("locations")) {
      let states = localStorage.getItem("locations").split(",");

      setSelectedState(states.map((state) => ({ label: state, value: state })));
      dispatch(
        getAllContacts(
          `location=${states.join(",")}&page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
    } else {
      dispatch(
        getAllContacts(
          `page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
    }
  }, [dispatch, rowsPerPage]);

  useEffect(() => {
    if (stateLabors.length === 0) {
      dispatch(getStateLaborPricingList());
    }
    dispatch(listEmailTemplates("GC"));
  }, [dispatch, stateLabors.length]);

  useEffect(() => {
    if (!user) {
      setLoginModalShow(true);
      return;
    } else if (!hasFeatureAccess) {
      enforceAccess();
    }
    if (contacts !== null) {
      setMaxPage(Math.ceil(count / rowsPerPage));
    }
  }, [contacts]);

  // useEffect(() => {
  //   if (stateLabors.length === 0) {
  //     dispatch(getStateLaborPricingList());
  //   }
  // }, [stateLabors.length]);

  const handleStateChange = (e) => {
    if (e.length <= 5) {
      setSelectedState(e);
      let states = e.map((state) => state.value).join(",");
      dispatch(
        getAllContacts(
          `location=${states}&phase=${phaseFilter}&page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
      localStorage.setItem("locations", states);
    } else {
      toastError("You can filter only 5 States");
    }
  };

  const handlePhaseChange = (e) => {
    setPhaseFilter(e);
    let states = selectedState.map((state) => state.value).join(",");
    dispatch(
      getAllContacts(
        `location=${states}&sort=${
          sortState["order"] == "asc"
            ? sortState["prop"]
            : "-" + sortState["prop"]
        }`
      )
    );
  };

  const handleRowRedirect = (e) => {
    navigate(`/project_board/${e.url_slug}`);
  };

  const onSortChange = useCallback(
    (nextSort) => {
      setSortState(nextSort);
      let states = selectedState.map((state) => state.value).join(",");
      dispatch(
        getAllContacts(
          `location=${states}&phase=${phaseFilter}&page=${currentPage}&page_size=${rowsPerPage}&sort=${
            nextSort["order"] == "asc"
              ? nextSort["prop"]
              : "-" + nextSort["prop"]
          }`
        )
      );
    },
    [selectedState, phaseFilter, rowsPerPage, sortState]
  );

  const onRowsPerPageChange = useCallback(
    (rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
      let states = selectedState.map((state) => state.value).join(",");
      setCurrentPage(1);
      dispatch(
        getAllContacts(
          `location=${states}&phase=${phaseFilter}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
    },
    [selectedState, phaseFilter, sortState]
  );

  const onPaginationChange = useCallback(
    (nextPage) => {
      setCurrentPage(nextPage);
      let states = selectedState.map((state) => state.value).join(",");
      dispatch(
        getAllContacts(
          `location=${states}&page=${nextPage}&page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
    },
    [selectedState, phaseFilter, rowsPerPage, sortState]
  );
  const openEmailModalHandler = () => {
    if (!"auth") {
      setLoginModalShow(true);
      return;
    } else if (!isSubscriptionActive(user, price_id, user, free_mode_count)) {
      setPaymentModalShow(true);
      return;
    } else {
      setShowEmailModal(true);
    }
    return;
  };

  const closeEmailModalHandler = () => {
    // if (emailTemplateBody) {
    //   emailTemplateBody.subject = "";
    //   emailTemplateBody.text = "";
    //   setSelectedTemplate("");
    // }
    setEmailContent("");
    setSubjectContent("");
    setShowEmailModal(false);
  };

  const handleLoadContactByEvent = () => {
    setSelectedContacts([]);
    setMainCheckboxChecked(false);
    setEmailContent("");
    setSubjectContent("");
    setShowEmailModal(false);
    if (localStorage.getItem("locations")) {
      let states = localStorage.getItem("locations").split(",");

      setSelectedState(states.map((state) => ({ label: state, value: state })));
      dispatch(getAllContacts(`location=${states.join(",")}`));
    } else {
      dispatch(getAllContacts());
    }
  };

  const handleSetPaymentModal = () => {
    setPaymentModalShow(true);
  };

  const formatDatToUS = (value) => {
    const date = new Date(value);
    const formatter = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formatter.format(date);
  };
  const standardizeUrl = (url) => {
    // Check if the URL starts with 'http://' or 'https://', if not prepend 'https://'
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }

    // Ensure it uses 'https://'
    return url.replace(/^http:\/\//i, "https://");
  };

  useEffect(() => {
    setSubjectContent(emailTemplateBody?.subject);
    setEmailContent(emailTemplateBody?.text);
  }, [emailTemplateBody]);

  useEffect(() => {
    dispatch(fetchPageFavorite("Contact Outreach"));
  }, [dispatch]);

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Contact Outreach" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({ page_name: "Contact Outreach", page_url: path })
      );
    }
  };

  const downloadContacts = () => {
    // handleMainCheckboxChange();

    const contactIds = selectedContacts;
    // .filter((contact, index) => index + 1 <= 50)
    // .map((contact) => contact.contact_id);

    dispatch(downloadContactsList(contactIds));
    handleLoadContactByEvent();
  };
  const handleFetchClevelContacts = (c_level) => {
    if (localStorage.getItem("locations")) {
      let states = localStorage.getItem("locations").split(",");

      setSelectedState(states.map((state) => ({ label: state, value: state })));
      dispatch(
        getAllContacts(`location=${states.join(",")}&c_level=${c_level}`)
      );
    } else {
      dispatch(getAllContacts(`c_level=${c_level}`));
    }
  };
  const handleFetchEstimatingContact = (estimating) => {
    if (localStorage.getItem("locations")) {
      let states = localStorage.getItem("locations").split(",");

      setSelectedState(states.map((state) => ({ label: state, value: state })));
      dispatch(
        getAllContacts(`location=${states.join(",")}&estimating=${estimating}`)
      );
    } else {
      dispatch(getAllContacts(`estimating=${estimating}`));
    }
  };

  const handleToggleContactType = (e) => {
    if (e.target.checked) {
      setEstimatingStatus(true);
      handleFetchEstimatingContact(true);
    } else {
      setEstimatingStatus(false);
      handleFetchClevelContacts(true);
    }
  };
  return (
    <>
      <Container fluid className="justify-content-center">
        <Navbar className="bg-body-tertiary">
          <Container className="justify-content-start col-md-12">
            <Row className="align-self-center ">
              <Col md={10}>
                <Row className="justify-content-between">
                  <Col md={2}>
                    <img
                      alt=""
                      src={ContactOutreachIcon}
                      width="60"
                      height="60"
                      className="d-inline-block align-top"
                    />
                  </Col>
                  <Col md={10} className="align-self-center ">
                    <h1 className="align-self-center">Contact Outreach</h1>
                  </Col>
                </Row>
              </Col>
              {user && (
                <Col md={1} className="align-self-center">
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={isFavorite ? solidStar : regularStar}
                    style={{
                      color: isFavorite ? "gold" : "transparent",
                      stroke: isFavorite ? "" : "gold",
                      strokeWidth: isFavorite ? "0" : "20",
                      cursor: "pointer",
                    }}
                    onClick={handleFavoriteToggle}
                    className="fw-bolder"
                  />
                </Col>
              )}
              <Col md={2}></Col>
            </Row>
            <Row className="ms-2">
              <Col md={10}>
                <p className="text-start">
                  Send emails or download contact lists to connect with key
                  people. Once you engage with a contact, they’re automatically
                  removed from the list. Each list ensures no company appears
                  more than once, and only one person per company is included.
                </p>
              </Col>
            </Row>
          </Container>
        </Navbar>
        <Row
          style={{ backgroundColor: " rgb(17 93 203)", color: "#fff" }}
          className="p-5 justify-content-center "
        >
          <Col className="col-md-9 text-start ">
            <Row>
              <Col className="justify-content-between d-flex ">
                <Col className="d-flex col-md-8">
                  <Col className="col-md-2 me-5">
                    <div
                      className=" p-3 rounded-3"
                      style={{ backgroundColor: "rgb(13 76 167)" }}
                    >
                      <h5 className="fw-bolder"> Contacts</h5>
                      <h5 className="fw-bolder">
                        <CountUp end={contacts?.length} />
                      </h5>
                    </div>
                  </Col>
                  <Col className="col-md-2">
                    <div
                      className=" p-3 rounded-3"
                      style={{ backgroundColor: "rgb(13 76 167)" }}
                    >
                      <h5 className="fw-bolder">Companies</h5>
                      <h5 className="fw-bolder">
                        {" "}
                        <CountUp end={contacts?.length} />
                      </h5>
                    </div>
                  </Col>
                  <Col className="col-md-2 ms-5">
                    <div
                      className=" p-3 rounded-3"
                      style={{ backgroundColor: "rgb(13 76 167)" }}
                    >
                      <h5 className="fw-bolder">Activities</h5>
                      <h5 className="fw-bolder">
                        {" "}
                        <CountUp end={total_email_sent} />
                        /50
                      </h5>
                    </div>
                  </Col>
                </Col>
                <Col className="col-md-4 ms-5 justify-content-end align-self-center">
                  <Row className="flex-column">
                    <Col>
                      <h4 className="fw-bolder">Contact Type</h4>
                    </Col>

                    <Col md={5}>
                      <StyledSwitch>
                        <Form.Check
                          type="switch"
                          id="availability-switch"
                          className="custom-toggle ms-3 fw-bolder"
                          checked={estimatingStatus}
                          onChange={(e) => handleToggleContactType(e)}
                        />
                      </StyledSwitch>
                    </Col>
                  </Row>
                </Col>

                <Col className="col-md-1 justify-content-end align-self-end mt-5">
                  <div className=" p-3 rounded-3">
                    <a
                      href="https://learn.ganarpro.com/contact-outreach/"
                      target="_blank"
                      className="text-decoration-none"
                      rel="noreferrer"
                    >
                      {" "}
                      <Row>
                        <Col className="col-2">
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            style={{ color: "#fff" }}
                          />
                        </Col>

                        <Col style={{ color: "#fff" }} className=" col-1">
                          Help
                        </Col>
                      </Row>
                    </a>
                  </div>
                </Col>
              </Col>

              {/*<Col className="col-md-2">*/}
              {/*  <h3>Favorites</h3>*/}
              {/*  <div*/}
              {/*    className=" p-3 rounded-3"*/}
              {/*    style={{ backgroundColor: "rgb(13 76 167)" }}*/}
              {/*  >*/}
              {/*    <h5 className="fw-bolder">Total </h5>*/}
              {/*    <h5 className="fw-bolder">45</h5>*/}
              {/*  </div>*/}
              {/*</Col>*/}
            </Row>
          </Col>
        </Row>
        <br></br>
        <Row className="ps-5 ">
          <Col sm={12} className="text-start">
            {loading || downLoading ? (
              <div className="spinner-overlay">
                <div className="spinner-container">
                  <Spinner />
                </div>
              </div>
            ) : user == null ? (
              <>
                <h3 className="pt-4 text-danger">
                  Login to view the contacts!
                </h3>
              </>
            ) : contacts == null ? (
              <>
                <h3 className="pt-4 text-danger">Unable to get Contacts!</h3>
              </>
            ) : error != null && error.messages != null ? (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  {error.messages.map((messageObj, index) => (
                    <div key={index}>
                      <p className="pt-4 text-center text-danger">
                        <b>{messageObj.message}</b>
                      </p>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              (
                <Row className="justify-content-center">
                  <Col className="col-md-2">
                    <Row className=" flex-column">
                      <Col md={6} className="mt-5 ms-5 align-self-lg-start ">
                        <h3 className="fw-bolder">Recent Outreach</h3>
                        {recent_emails?.map((recent, index) => {
                          return (
                            <Row key={index}>
                              {recent.date_sent && (
                                <Col>{formatDatToUS(recent.date_sent)}</Col>
                              )}
                              <Col>{recent.email_count}</Col>
                            </Row>
                          );
                        })}
                      </Col>
                      <Col md={7} className="mt-5 ms-5 align-self-lg-start ">
                        <h3 className="fw-bolder">Recent Download</h3>
                        {recent_downloads?.map((recent, index) => {
                          return (
                            <Row key={index}>
                              {recent.date_sent && (
                                <Col>{formatDatToUS(recent.date_sent)}</Col>
                              )}
                              <Col>{recent.download_count}</Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  </Col>

                  <Col md={9}>
                    <DatatableWrapper
                      highlightOnHover
                      body={contacts}
                      headers={STORY_HEADERS}
                      paginationOptionsProps={{
                        initialState: {
                          rowsPerPage: rowsPerPage,
                          options: [20, 50, 100, 200, 500],
                        },
                      }}
                    >
                      <Row className="mb-4 p-2">
                        <Col
                          xs={6}
                          sm={6}
                          lg={3}
                          className="d-flex flex-col justify-content-lg-end align-items-center justify-content-sm-start mb-2 mb-sm-0"
                        >
                          <Form.Group style={{ width: "95%" }}>
                            <Form.Label className="text-start fw-bolder">
                              State
                            </Form.Label>
                            <MultiStateSelection
                              stateLabors={stateLabors}
                              handleChange={handleStateChange}
                              selected={selectedState}
                            />
                          </Form.Group>
                          {/*<Select options={regions} />*/}
                        </Col>

                        <Col className="col-md-1 align-self-end">
                          {/*<Button onClick={openEmailModalHandler}>*/}
                          {/*  Send Mass Email*/}
                          {/*</Button>*/}
                        </Col>
                        <Col
                          xs={3}
                          lg={2}
                          className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                        >
                          <PaginationOptions
                            controlledProps={{
                              rowsPerPage,
                              onRowsPerPageChange,
                            }}
                            style={{ width: "100%" }}
                          ></PaginationOptions>
                        </Col>
                        <Col className="col-md-2 align-self-end">
                          <Button onClick={downloadContacts}>
                            {downLoading ? (
                              <h4 className="p-2 mb-1">
                                Loading <Spinner animation="border" size="sm" />{" "}
                              </h4>
                            ) : (
                              <h5 className="p-0 m-0">Download</h5>
                            )}
                          </Button>
                        </Col>
                        <Col
                          md={2}
                          className="d-flex align-items-end align-self-end"
                        >
                          <Pagination
                            controlledProps={{
                              currentPage,
                              maxPage,
                              onPaginationChange,
                            }}
                          />
                        </Col>
                        {/*<Col*/}
                        {/*  xs={6}*/}
                        {/*  sm={6}*/}
                        {/*  lg={2}*/}
                        {/*  className="d-flex flex-col justify-content-end align-items-end"*/}
                        {/*>*/}
                        {/*  <PhaseFilters*/}
                        {/*    value={phaseFilter}*/}
                        {/*    onChange={handlePhaseChange}*/}
                        {/*  />*/}
                        {/*</Col>*/}
                        {/*<Col*/}
                        {/*  xs={6}*/}
                        {/*  lg={2}*/}
                        {/*  className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"*/}
                        {/*>*/}
                        {/*  <PaginationOptions*/}
                        {/*    controlledProps={{*/}
                        {/*      rowsPerPage,*/}
                        {/*      onRowsPerPageChange,*/}
                        {/*    }}*/}
                        {/*    style={{ width: "100%" }}*/}
                        {/*  ></PaginationOptions>*/}
                        {/*</Col>*/}
                        {/*<Col*/}
                        {/*  xs={6}*/}
                        {/*  lg={5}*/}
                        {/*  className="d-flex flex-col justify-content-end align-items-end"*/}
                        {/*>*/}
                        {/*  <Pagination*/}
                        {/*    controlledProps={{*/}
                        {/*      currentPage,*/}
                        {/*      maxPage,*/}
                        {/*      onPaginationChange,*/}
                        {/*    }}*/}
                        {/*  />*/}
                        {/*</Col>*/}
                      </Row>
                      <Table className="research-table">
                        <TableHeader
                          controlledProps={{
                            sortState,
                            onSortChange,
                          }}
                        ></TableHeader>
                        <TableBody />
                      </Table>
                    </DatatableWrapper>
                  </Col>
                </Row>
              )
            )}
          </Col>
        </Row>
      </Container>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        closeButton={false}
      />
      <SubscriptionModal
        backdrop="static"
        size="lg"
        show={paymentModalShow}
        dialogClassName="modal-100w"
        closeButton={false}
      />
      <EmailModal
        app="contact_outreach"
        company_name={profile?.company_name}
        show={emailModalShow}
        onHide={() => {
          setEmailModalShow(false);
          setContact("");
        }}
        onChange={handleSendEmail}
      />
      {/*<Modal*/}
      {/*  title={`New message`}*/}
      {/*  show={showEmailModal}*/}
      {/*  keyboard={false}*/}
      {/*  onCloseModal={closeEmailModalHandler}*/}
      {/*>*/}
      {/*  <SendContactMassEmailForm*/}
      {/*    selectedContacts={selectedContacts}*/}
      {/*    emailTemplates={emailTemplates}*/}
      {/*    contact=""*/}
      {/*    handleCloseEmailModal={closeEmailModalHandler}*/}
      {/*    handleLoadContactByEvent={handleLoadContactByEvent}*/}
      {/*    emailContent={emailContent}*/}
      {/*    subjectContent={subjectContent}*/}
      {/*    setEmailContent={setEmailContent}*/}
      {/*    setSubjectContent={setSubjectContent}*/}
      {/*    emailTemplateBody={emailTemplateBody}*/}
      {/*    handleSetPaymentModal={handleSetPaymentModal}*/}
      {/*    price_id={price_id}*/}
      {/*    closeEmailModalHandler={closeEmailModalHandler}*/}
      {/*  />*/}
      {/*</Modal>*/}
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
      <SubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
    </>
  );
};

export default ContactOutreach;
