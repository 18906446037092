import React, { useEffect, useState } from "react";
import renderHTML from "react-render-html";
import ProposalPreviewFormHeader from "./ProposalPreviewFormHeader";
import ProposalPreviewFormFooter from "./ProposalPreviewFormFooter";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";

const SignatureBlock = () => {
  const proposalValues = useSelector((state) => state.proposalValues);
  const sections = useSelector((state) => state.proposalSections);

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);
  const paint_app = ganarpro_app
    ? ganarpro_app == "paint"
    : !user?.is_cleaning_company;

  const dispatch = useDispatch();

  return (
    <div
      style={{
        fontSize: "16px",
        textTransform: "capitalize",
        marginTop: "10px",
      }}
    >
      {sections.davis_bacon && (
        <div style={{ fontSize: "14px" }}>
          <p>
            We adhere fully to all federal contracting requirements. Certified
            Payroll, and Davis Bacon Wages.
          </p>
        </div>
      )}
      {sections.union_requirement && (
        <div style={{ fontSize: "14px" }}>
          <p>
            This price reflects local union agreement labor practices, we will
            provide a Project Labor Agreement (PLA) for this project.
          </p>
        </div>
      )}
      {sections.sign && (
        <div>
          {(sections.davis_bacon || sections.union_requirement) && <br />}
          <h4>Client Approval: </h4>
          <div className="mb-3 mt-3">
            <p>
              Client Name: __________________________________________________
            </p>
          </div>

          <div className="mb-3">
            <p>
              Date: _________________________________________________________
            </p>
          </div>

          <div className="mb-3">
            <p>
              Signature*: ____________________________________________________
            </p>
          </div>
          <p style={{ fontSize: "14px" }}>
            * Signing Indicates Full Agreement with The Project Specifications
            and The Outlined Pricing in The Scope of Work.
          </p>
        </div>
      )}

      <br></br>
      {sections.disclosure && !paint_app ? (
        <div style={{ marginTop: sections.sign ? "20px" : "" }}>
          <section>
            <h4>GENERAL NOTES</h4>
            <ul>
              <li>
                If Non-Punch List Items Create the Need for Repeat Work, An
                Additional Charge May Be Assessed.
              </li>
              <li>Bidder included sales tax.</li>
              <li>Project schedule understood and accepted. </li>
              <li>
                Bidder has included all costs to meet the schedule and accounts
                for all overtime and shift time necessary to complete work and
                make up for delays that are through no fault of others.
              </li>
            </ul>
            <p></p>
          </section>
          <section>
            <h4>EXCLUSIONS</h4>
            <ul>
              <li>Dumpster removal.</li>
              <li>
                Out of scope work, such as:
                <ul>
                  <li>Power washing exterior walkways.</li>
                  <li>Exterior signs.</li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
      ) : (
        sections.disclosure &&
        paint_app && (
          <div
            dangerouslySetInnerHTML={{ __html: proposalValues?.disclosure }}
            style={{ marginBottom: "30px", fontSize: "14px" }}
          />
        )
      )}
    </div>
  );
};

export default SignatureBlock;
