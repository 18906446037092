import React, { useState } from "react";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogForm from "../../../components/forms/logCall/LogForm";
import Modal from "../../../components/modal/Modal";

const PhoneLogContainer = ({ row }) => {
  const [showLogCallModal, setShowLogCallModal] = useState(false);
  return (
    <div className="mx-5">
      {row.phone &&
        <FontAwesomeIcon
          icon={faPhone}
          className="border-1"
          onClick={() => setShowLogCallModal(true)}
          style={{ color: "rgb(17, 93, 203)" }}
        />
      }
      <Modal
        title={`${row?.name}’s Phone Details`}
        show={showLogCallModal}
        onCloseModal={() => setShowLogCallModal(false)}
      >
        <LogForm
          phone={row?.phone}
          contactId={row?.id}
          setShowLogCallModal={setShowLogCallModal}
        />
      </Modal>
    </div>
  );
};

export default PhoneLogContainer;
