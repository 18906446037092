import React, { useEffect, useState } from "react";
import { Col, Row, Table, Form } from "react-bootstrap";
import { StyledDocument } from "./ProposalPreviewFrom.styled";
import { useDispatch, useSelector } from "react-redux";
import ProposalPreviewFormHeader from "./ProposalPreviewFormHeader";
import ProposalPreviewFormFooter from "./ProposalPreviewFormFooter";
import ProposalProjectType from "./ProposalProjectType";
import { getUserDetail } from "../../store/actions/users/users.actions";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";
import AutoComplete from "./AutoComplete";
import ProposalTemplate from "../../views/proposal/proposalTemplate";
import PaintProposalTemplate from "../PaintProposalPreviewForm/ProposalTemplate";

const ProposalPreviewForm = ({ disable_fields }) => {
  const { projectTypeDetail } = useSelector((state) => state.projectTypeDetail);
  const {
    project_name,
    project_address,
    project_street,
    project_city,
    project_state,
    project_zip,
    bid_amount,
    project_contact_1_name,
    project_contact_1_phone,
    project_contact_1_email,
    project_contact_2_name,
    project_contact_2_email,
    project_contact_2_phone,
    project_contact_3_name,
    project_contact_3_email,
    project_contact_3_phone,
    project_contact_4_name,
    project_contact_4_email,
    project_contact_4_phone,
    customer_company_name,
    customer_street,
    customer_state,
    customer_city,
    customer_zip,
    customer_address,
    border_color
  } = useSelector((state) => state.proposalValues);

  const [ proposalValues, setProposalValues ] = useState({});
  const userDetails = useSelector((state) => state.userDetails);
  const { user: authUser } = useSelector((state) => state.auth);
  const sections = useSelector((state) => state.proposalSections);
  const current_date = new Date().toUTCString().slice(0, 16);
  const { user } = userDetails;
  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);
  const paint_app = ganarpro_app ? ganarpro_app == "paint" : !user?.is_cleaning_company;

  const dispatch = useDispatch();
  useEffect(() => {
    if (authUser) {
      dispatch(getUserDetail());
    }
  }, [authUser, dispatch]);

  const handleChange = (evt) => {
    const { name, value: newValue } = evt?.target || evt;
    dispatch({ type: PROPOSAL_SET_VALUES, payload: { [name]: newValue } });
    setProposalValues((prev) =>({
      ...prev,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    setProposalValues({
      project_name: project_name,
      project_address: project_address,
      project_street: project_street,
      project_city: project_city,
      project_state: project_state,
      project_zip: project_zip,
      bid_amount: bid_amount,
      project_contact_1_name: project_contact_1_name,
      project_contact_1_phone: project_contact_1_phone,
      project_contact_1_email: project_contact_1_email,
      project_contact_2_name: project_contact_2_name,
      project_contact_2_email: project_contact_2_email,
      project_contact_2_phone: project_contact_2_phone,
      project_contact_3_name: project_contact_3_name,
      project_contact_3_email: project_contact_3_email,
      project_contact_3_phone: project_contact_3_phone,
      project_contact_4_name: project_contact_4_name,
      project_contact_4_email: project_contact_4_email,
      project_contact_4_phone: project_contact_4_phone,
      customer_company_name: customer_company_name,
      customer_street: customer_street,
      customer_state: customer_state,
      customer_city: customer_city,
      customer_zip: customer_zip,
      customer_address: customer_address,
      border_color: border_color
    })
  },[
    project_name,
    project_address,
    project_street,
    project_city,
    project_state,
    project_zip,
    bid_amount,
    project_contact_1_name,
    project_contact_1_phone,
    project_contact_1_email,
    project_contact_2_name,
    project_contact_2_email,
    project_contact_2_phone,
    project_contact_3_name,
    project_contact_3_email,
    project_contact_3_phone,
    project_contact_4_name,
    project_contact_4_email,
    project_contact_4_phone,
    customer_company_name,
    customer_street,
    customer_state,
    customer_city,
    customer_zip,
    customer_address,
    border_color
  ]);

  const resetValue = (name) => {
    dispatch({ type: PROPOSAL_SET_VALUES, payload: { [name]: "" } });
    setProposalValues((prev) => ({
      ...prev,
      [name]: ""
    }));
  };

  useEffect(() => {
    if(!sections.project_address){
      resetValue("project_address");

    } else if (!sections.client_name){
      resetValue("customer_company_name");

    } else if (!sections.client_address) {
      resetValue("customer_address");

    }

  },[sections])

  return (
    <StyledDocument>
      <section className="bg-white p-5" style={proposalValues?.border_color ? { border: `2px solid ${proposalValues?.border_color}` } : {}}>
        <ProposalPreviewFormHeader disable_fields={disable_fields} />
        <Row>
          {sections.contact_name || sections.today_date || sections.contact_phone_email ?
            <Table bordered className="my-4">
              <thead className="fs-4">
                <tr>
                  <th colSpan={sections.today_date && sections.contact_name ? 0 : sections.today_date || sections.contact_name ? 2 : 3}>Submitted By</th>
                  {sections.contact_name && <td>{user?.proposal_point_contact_name}</td>}
                  {sections.today_date && <td>Date: {current_date}</td>}
                </tr>
              </thead>
              <tbody>
                {sections.contact_phone_email &&
                  <tr>
                    <td>Contact</td>
                    <td>{user?.proposal_point_contact_phone}</td>
                    <td>{user?.proposal_point_contact_email}</td>
                  </tr>
                }
              </tbody>
            </Table>
          : ""}
          <Table bordered className="my-4">
            <thead className="fs-3">
              <tr>
                <th className="text-start">Project Name</th>
                <th className="text-center">
                  <Form.Control
                    type="text"
                    name="project_name"
                    placeholder="Project Name"
                    className="text-center w-100"
                    style={{ border: 'none', background: 'none', fontSize: '16px', fontWeight: 'bold' }}
                    value={proposalValues.project_name}
                    onChange={handleChange}
                    disabled={disable_fields? true : false}
                    />
                 </th>
              </tr>
            </thead>
            {sections.project_address? (
              <tbody>
                <tr className="fs-4">
                  <td className="text-start">Project Address</td>
                  <td className="text-center">
                    <AutoComplete
                      onAddressSelect={handleChange}
                      placeholder="Project Address"
                      formControlName="project_address"
                      initialValue={proposalValues?.project_address}
                      disable_fields={disable_fields? true : false}
                      />
                  </td>
                </tr>
              </tbody>
            ) : ""}
          </Table>
          {sections.client_name || sections.client_address ? (
            <Table bordered className="my-4">
              <tbody className="fs-4">
                {sections.client_name &&
                  <tr className="fs-4">
                    <th>Customer</th>
                    <td>
                      <Form.Control
                        type="text"
                        name="customer_company_name"
                        className="text-center w-100"
                        placeholder="Customer Company Name"
                        style={{ border: 'none', background: 'none', fontSize: '14px' }}
                        onChange={handleChange}
                        value={proposalValues.customer_company_name}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                  </tr>
                }
                {sections.client_address &&
                  <tr>
                    <th>Address</th>
                    <td>
                      <AutoComplete
                        onAddressSelect={handleChange}
                        placeholder="Customer Address"
                        formControlName="customer_address"
                        initialValue={proposalValues?.customer_address}
                        disable_fields={disable_fields? true : false}
                      />
                    </td>
                  </tr>
                }

              </tbody>
            </Table>
          ) : ""}
          {sections.contact1 || sections.contact2 || sections.contact3 || sections.contact4 ? (
            <Table bordered className="my-4">
              <thead className="fs-4">
                <tr>
                  <th className="text-start">Project Team Name</th>
                  <th className="text-center">Phone Number</th>
                  <th className="text-center">Email</th>
                </tr>
              </thead>
              <tbody>
                {sections.contact1 && (
                  <tr className="fs-5">
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_1_name"
                        className="w-100"
                        placeholder="Name"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_1_name}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_1_phone"
                        className="text-center w-100"
                        placeholder="Phone Number"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_1_phone}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-center">
                      <Form.Control
                        type="text"
                        name="project_contact_1_email"
                        className="text-center w-100"
                        placeholder="Email"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_1_email}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                  </tr>
                )}
                {sections.contact2 && (
                  <tr className="fs-5">
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_2_name"
                        className="w-100"
                        placeholder="Name"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_2_name}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_2_phone"
                        className="text-center w-100"
                        placeholder="Phone Number"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_2_phone}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-center">
                      <Form.Control
                        type="text"
                        name="project_contact_2_email"
                        className="text-center w-100"
                        placeholder="Email"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_2_email}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                  </tr>
                )}
                {sections.contact3 && (
                  <tr className="fs-5">
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_3_name"
                        className="w-100"
                        placeholder="Name"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_3_name}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_3_phone"
                        className="text-center w-100"
                        placeholder="Phone Number"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_3_phone}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-center">
                      <Form.Control
                        type="text"
                        name="project_contact_3_email"
                        className="text-center w-100"
                        placeholder="Email"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_3_email}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                  </tr>
                )}
                {sections.contact4 && (
                  <tr className="fs-5">
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_4_name"
                        className="w-100"
                        placeholder="Name"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_4_name}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-start">
                      <Form.Control
                        type="text"
                        name="project_contact_4_phone"
                        className="text-center w-100"
                        placeholder="Phone Number"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_4_phone}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                    <td className="text-center">
                      <Form.Control
                        type="text"
                        name="project_contact_4_email"
                        className="text-center w-100"
                        placeholder="Email"
                        style={{ border: 'none', background: 'none' }}
                        onChange={handleChange}
                        value={proposalValues.project_contact_4_email}
                        disabled={disable_fields? true : false}
                      />
                    </td>
                  </tr>
                )}
                {user?.job_site_contact_name && (
                  <tr className="fs-5">
                    <td className="text-start">
                      {user?.job_site_contact_name}
                    </td>
                    <td className="text-start">
                      {user?.job_site_contact_phone}
                    </td>
                    <td className="text-center">
                      {user?.job_site_contact_email}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : "" }
        </Row>
        <ProposalPreviewFormFooter page="1" />
      </section>
      {paint_app ? <PaintProposalTemplate/> : <ProposalProjectType disable_fields={disable_fields}/>}
    </StyledDocument>
  );
};

export default ProposalPreviewForm;
