import React from 'react';
import { Form } from 'react-bootstrap';
import "./Color.css"

const ColorPicker = ({ borderColor, onBorderColorChange, className }) => {
  const handleColorChange = (e) => {
    onBorderColorChange(e.target.value);
  };

  return (
    <Form.Group className={`color ${className}`}>
      <Form.Label>Set Border Color</Form.Label>
      <Form.Control
        type="color"
        value={borderColor || "#FFFFFF"}
        onChange={handleColorChange}
      />
    </Form.Group>
  );
};

export default ColorPicker;
