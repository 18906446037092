import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import preQualImg from "../../assets/img/prequalify.jpg";
import projectImg from "../../assets/img/projects.jpg";
import proposalImg from "../../assets/img/proposal.jpg";
import trialImg from "../../assets/img/trial.jpg";
import calculatorImg from "../../assets/img/calculator.jpg";
import prequelMasterKeyImg from "../../assets/img/prequel_masterkey-min.jpeg";
import ContactOutreachIcon from "../../assets/img/contactOutreachIcon.svg";
import capabilitiesImg from "../../assets/img/capabilities-min.jpg";
import siteVisit from "../../assets/img/site_visit.jpg";
import { usePostHog } from "posthog-js/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorites,
  fetchPageView,
  getManageUserBilling,
  getUserDetail,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { faExternalLinkAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { formatDate } from "../../utils/helpers/helper";
import { Pages } from "./data/Pages";

let cardData = [
  {
    id: "projects_overview",
    title: "Projects",
    description:
      "Construction project data for prospecting, analyzing, researching.",
    imgSrc: projectImg,
    linkTo: "/projects_overview",
  },
  {
    id: "cleanup_calculator_overview",
    title: "Calculator",
    description:
      "Calculates bid amount estimates and analyze potential cost and profits. Win more bids.",
    imgSrc: calculatorImg,
    linkTo: "/cleanup_calculator_overview",
  },
  {
    id: "proposal",
    title: "Cleanup Proposal",
    description: "Standardize proposal creation with templates. Satisfy customer needs with the correct scope of work.",
    imgSrc: proposalImg,
    linkTo: "/proposal",
  },
  {
    id: "paint_proposal",
    title: "Paint Proposal",
    description: "Standardize proposal creation with templates. Satisfy customer needs with the correct scope of work.",
    imgSrc: proposalImg,
    linkTo: "/paint_proposal",
  },
  {
    id: "planify",
    title: "Planify",
    description:
      "Unleash the Power of Pre-Qualification and Dynamic Plan Rooms.",
    imgSrc: preQualImg,
    linkTo: "/planify",
  },
  {
    id: "qualkey",
    title: "QualKey",
    description:
      "Centralize company information to utilize in vendor application forms. Gather and answer up to 240 questions.",
    imgSrc: prequelMasterKeyImg,
    linkTo: "/qualkey",
  },
  {
    id: "capability_statement",
    title: "Capability Statement",
    description:
      "A single-page summary highlighting your business operations and main contact point.",
    imgSrc: capabilitiesImg,
    linkTo: "/capability_statement",
  },
  {
    id: "contact_outreach",
    title: "Contact Outreach",
    description:
      "Tailored email marketing and contact management tool for North American construction industry professionals.",
    imgSrc: ContactOutreachIcon,
    linkTo: "/contact_outreach",
  },
  {
    id: "site_visit",
    title: "Site Visit Sheet",
    description:
      "Facilitate and manage construction site visits, streamlining the process of recording observations",
    imgSrc: siteVisit,
    linkTo: "/site_visit",
  },
  // Add more cards as needed
];

const Home = () => {
  const { user } = useSelector((state) => state.auth);
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, user: profile } = userDetails;
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { pageList } = useSelector((state) => state.listPageView);
  const { favorites } = useSelector((state) => state.ListFavoritePages);
  const posthog = usePostHog();

  let [searchParams] = useSearchParams();
  const params_app = searchParams.get("app");

  useEffect(() => {
    dispatch(fetchPageView());
  }, [dispatch]);

  useEffect(() => {
    if (user && !loading) {
      posthog?.identify(`${user.id}-${user.email}`, {
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
      });
      let data =
        localStorage[
          "ph_" + process.env.REACT_APP_PUBLIC_POSTHOG_KEY + "_posthog"
        ];
      if (data) {
        data = JSON.parse(data);
        posthog.alias(data["distinct_id"], `${user.id}-${user.email}`);
      }
    }
  }, [user]);

  useEffect(() => {
    if (profile?.is_cleaning_company) {
      dispatch(getUserDetail());
    }
  }, [dispatch, profile?.is_cleaning_company]);

  useEffect(()=> {
    let ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);
    let isSwitchButton = localStorage.getItem(`isSwitchButton_${user?.id}`);
    if (params_app){
      ganarpro_app = localStorage.setItem(`ganarpro_app${user?.id}`, params_app);
      localStorage.setItem(`isSwitchButton_${user?.id}`, `${params_app=="cleaning"?"false":"true"}`);
      isSwitchButton = localStorage.getItem(`isSwitchButton_${user?.id}`);
      navigate("/")
    }

    if (profile) {
      isSwitchButton = localStorage.getItem(`isSwitchButton_${user?.id}`)
        ? localStorage.getItem(`isSwitchButton_${user?.id}`)
        : localStorage.setItem(`ganarpro_app${user?.id}`, "cleaning");

      if (profile?.features.length > 0) {
        cardData = cardData.filter((card) =>
          profile?.features.includes(card.title)
        );
      }
    } else {
      isSwitchButton = localStorage.getItem(`isSwitchButton_${user?.id}`)
        ? localStorage.getItem(`isSwitchButton_${user?.id}`)
        : localStorage.setItem(`ganarpro_app${user?.id}`, "cleaning");
    }

    switch (ganarpro_app) {
      case "cleaning":
        cardData = cardData.filter((card) => card.id !== "paint_proposal");
        break;

      case "paint":
        cardData = cardData.filter(
          (card) =>
            card.id !== "cleanup_calculator_overview" && card.id !== "proposal"
        );
        break;

      case "basic_cleaning":
        cardData = cardData.filter(
          (card) =>
            card.id == "cleanup_calculator_overview" || card.id == "proposal"
        );
        break;

      case "business":
        cardData = cardData.filter(
          (card) =>
            card.id !== "cleanup_calculator_overview" &&
            card.id !== "proposal" &&
            card.id !== "paint_proposal" &&
            card.id !== "site_visit"
        );
        break;

      default:
        cardData = cardData.filter((card) => card.id !== "paint_proposal");
        break;
    }
  }, [])

  useEffect(() => {
    dispatch(fetchPageFavorites());
  }, [dispatch]);

  const handleFavorite = (page) => {
    dispatch(addPageFavorite(page));
  };

  const handleUnfavorite = (pageUrl) => {
    dispatch(removePageFavorite(pageUrl));
  };

  return (
    <Container>
      <Row className="mt-3 justify-content-center">
        <Col md={7} style={{ height: "10em" }} className="col-7">
          <h1 className="fw-bolder">Welcome to Ganarpro!</h1>
          {!user?.subscriptions.length && (
            <p>Dont have a subscription? Checkout the following options.</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="col-7 justify-content-center mx-auto col-md-3">
          {user ? (
            ""
          ) : (
            <Link to="/signup" className="text-decoration-none text-dark">
              <Card className="">
                <Card.Img
                  variant="top"
                  src={trialImg}
                  style={{ width: "113%" }}
                />
                <Card.Body className="p-0">
                  <Card.Title className="fw-bolder">
                    Start with a free trial
                  </Card.Title>
                  <Card.Text>
                    Access Ganarpro Business Suite Cloud services.
                  </Card.Text>
                  <Button
                    variant="primary"
                    className="mt-auto align-self-start"
                  >
                    Start
                  </Button>
                </Card.Body>
              </Card>
            </Link>
          )}
        </Col>
      </Row>
      <Row className="my-3">
        <h2 className="text-start fw-bolder">Ganarpro services</h2>
      </Row>
      {favorites.length > 0 ? (
        <Row className=" my-5 ">
          {favorites?.map((card, index) => {
            if (index < 8) {
              return (
                <Col md={1} sm={1} className="mx-4 text-center">
                  <Link to={card.page_url} className="text-decoration-none">
                    <Row className="flex-column text-start">
                      {card.page_name == "Site Visit Generator" ? (
                        <>
                          <Col className="text-start">
                            <img
                              src={Pages["Site Visit Sheet"]?.imgSrc}
                              style={{ width: "50px", height: "50px" }}
                              alt="Site Visit Sheet"
                            />
                          </Col>
                          <Col>
                            <h5>Site Visit Sheet</h5>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col className="text-start">
                            <img
                              src={Pages[card.page_name]?.imgSrc}
                              style={{ width: "50px", height: "50px" }}
                              alt={card.page_name}
                            />
                          </Col>
                          <Col>
                            <h5>{card.page_name}</h5>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Link>
                </Col>
              );
            } else {
              return null; // Don't render anything for indexes 8 and above
            }
          })}
        </Row>
      ) : (
        <Row className="justify-content-center">
          {cardData.length > 0 &&
            cardData.map((card, index) => {
              return (
                <Col md={3} className="gy-3 my-md-5 my-xs-5 col-7">
                  <Link
                    to={card.linkTo}
                    className="text-decoration-none text-dark"
                  >
                    <Card className="h-100">
                      <Card.Img
                        variant="top"
                        src={card.imgSrc}
                        height={card.id === "contact_outreach" ? `100px` : ""}
                      />
                      <Card.Body className="d-flex flex-column">
                        <Card.Title className="fw-bolder">
                          {card.title}
                        </Card.Title>
                        <Card.Text className="display-10">
                          {card.description}
                        </Card.Text>
                        <Button
                          variant="primary"
                          className="mt-auto align-self-center"
                        >
                          Visit {card.title}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            })}
        </Row>
      )}
      {pageList && (
        <>
          <Row className="my-3">
            <h2 className="text-start fw-bolder">Resources</h2>
          </Row>
          <Row>
            <Tabs defaultActiveKey="recent" id="resource-tabs">
              <Tab eventKey="recent" title="Recent">
                <Table className="text-start">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Last Viewed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageList.map((resource, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to={resource?.page_url}
                            className="text-decoration-none"
                          >
                            {/*<FontAwesomeIcon icon={faStar} />{" "}*/}
                            {resource.page_name}
                          </Link>
                        </td>
                        <td>{resource.type}</td>
                        <td>{formatDate(resource.last_viewed)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="favorites" title="Favorites">
                <Table className="text-start">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {favorites?.map((resource, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to={resource?.page_url}
                            className="text-decoration-none"
                          >
                            {/*<FontAwesomeIcon icon={faStar} />{" "}*/}
                            {resource.page_name}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* Implement favorite resources here */}
              </Tab>
            </Tabs>
          </Row>
        </>
      )}
      <Row></Row>
      {/*<Row className="justify-content-center">*/}
      {/*  <Col md={3} className="gy-3 my-md-5 my-xs-5 col-7">*/}
      {/*    <Link*/}
      {/*      to="/contact_outreach"*/}
      {/*      className="text-decoration-none text-dark"*/}
      {/*    >*/}
      {/*      <Card className="h-100">*/}
      {/*        <Card.Img*/}
      {/*          variant="top"*/}
      {/*          src={ContactOutreachIcon}*/}
      {/*          height="100px"*/}
      {/*        />*/}
      {/*        <Card.Body className="d-flex flex-column">*/}
      {/*          <Card.Title className="fw-bolder">Contact Outreach</Card.Title>*/}
      {/*          <Card.Text>*/}
      {/*            Tailored email marketing and contact management tool for North*/}
      {/*            American construction industry professionals*/}
      {/*          </Card.Text>*/}
      {/*          <Button variant="primary" className="mt-auto align-self-center">*/}
      {/*            Visit Contact Outreach*/}
      {/*          </Button>*/}
      {/*        </Card.Body>*/}
      {/*      </Card>*/}
      {/*    </Link>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <br />
      <br />
      <Row className="text-start">
        <h2 className="fw-bolder">Useful Links</h2>
      </Row>
      <Row
        className="text-start"
        style={{ fontSize: "16px", marginTop: "25px" }}
      >
        <Col>
          <Link
            to="https://learn.ganarpro.com"
            style={{ textDecoration: "none" }}
          >
            Technical Documentation{" "}
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              className="new-tab-icon"
              style={{ fontSize: "14px" }}
            />
          </Link>
        </Col>
        <Col>
          <Link
            to="https://www.ganarpro.com/support/getting-started/"
            style={{ textDecoration: "none" }}
          >
            Getting Started{" "}
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              className="new-tab-icon"
              style={{ fontSize: "14px" }}
            />
          </Link>
        </Col>
      </Row>
      <Row
        className="text-start"
        style={{ fontSize: "16px", marginTop: "15px", marginBottom: "30px" }}
      >
        <Col>
          <Link to="https://www.ganarpro.com/products/">Ganarpro Services</Link>
        </Col>
        <Col>
          <Link
            to="/pricing"
            onClick={(e) => {
              if (user && user?.subscriptions?.length > 0) {
                e.preventDefault();
                dispatch(getManageUserBilling(user?.customer));
              }
            }}
          >
            Subscriptions
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
