import { updateObject } from "../../../utils/helpers/helper";
import { singleProject } from "../../../utils/requests/projects";
import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_TABLE_SUCCESS,
  FETCH_PROJECTS_FAIL,
  FETCH_SINGLE_PROJECT_SUCCESS,
  ARCHIVE_PROJECT_SUCCESS,
  UNARCHIVE_PROJECT_SUCCESS,
  PROJECT_ACTION_FAILED,
  FAVOURITE_PROJECT_SUCCESS,
  UNFAVOURITE_PROJECT_SUCCESS,
  UPDATE_FAVOURITE_PROJECT_SUCCESS,
  PROJECT_SAVE_SEARCH_SUCCESS,
  PROJECT_SAVE_SEARCH_START,
  PROJECT_SAVE_SEARCH_FAILED,
  PROJECT_DELETE_SEARCH_SUCCESS,
  PROJECT_DELETE_SEARCH_START,
  PROJECT_DELETE_SEARCH_FAILED,
  GET_EMAIL_TEMPLATE_START,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_FAILED,
  PROJECT_SITE_ROUTE_SUCCESS,
  PROJECT_REMOVE_SITE_ROUTE_SUCCESS,
  UPDATE_SITE_ROUTE_PROJECT_SUCCESS,
  SITE_ROUTE_NOTES_REQUEST,
  SITE_ROUTE_NOTES_SUCCESS,
  SITE_ROUTE_NOTES_FAILED,
  UPDATE_PRICING_MODAL,
  PROJECT_REMOVE_ALL_SITE_ROUTE_SUCCESS,
  UPDATE_LAST_CONTACT_REQUEST,
  UPDATE_LAST_CONTACT_SUCCESS,
  UPDATE_LAST_CONTACT_FAILED,
  UPDATE_PROJECT_NOTES_FAILED,
  UPDATE_PROJECT_NOTES_SUCCESS,
  UPDATE_PROJECT_NOTES_REQUEST,
  UPDATE_PROJECT_LAST_CONTACT_FAILED,
  UPDATE_PROJECT_LAST_CONTACT_SUCCESS,
  UPDATE_PROJECT_LAST_CONTACT_REQUEST,
  REMOVE_ACTIVITY_REQUEST,
  REMOVE_ACTIVITY_SUCCESS,
  REMOVE_ACTIVITY_FAILED,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAILED,
  UPDATE_ACCOUNT_LAST_CONTACT,
} from "../../actionTypes/index";
import {
  UPDATE_USER_PROJECT_BOARD_DATA_FAILURE,
  UPDATE_USER_PROJECT_BOARD_DATA_REQUEST,
  UPDATE_USER_PROJECT_BOARD_DATA_SUCCESS,
} from "../../constants/userProjectBoardData";

const initialState = {
  projects: null,
  count: 0,
  loading: false,
  next: null,
  previous: null,
  error: null,
  singleProject: null,
  favouriteProjects: [],
  siteRoutesProjects: [],
  siteRoutesNotes: {},
  loadingNotes: false,
  actionError: "",
  buildingTypes: [],
  phases: [],
  sizes: [],
  saveSearchSuccess: false,
  emailTemplate: null,
  emailTemplateError: null,
  email_templates: [],
  status: false,
  pricingModalShow: false,
  loadingDate: false,
  loadingProjectDate: false,
  lastDate: null,
  projectLastDate: null,
  last_delete_activity: null,
  deleting_activity: false,
};

const fetchProjectStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const fetchProjectSuccess = (state, action) => {
  const {
    count,
    next,
    previous,
    results,
    building_types,
    phases,
    sizes,
    email_templates,
  } = action.data;
  return updateObject(state, {
    count,
    next,
    previous,
    loading: false,
    projects: results,
    error: null,
    buildingTypes: building_types,
    sizes,
    phases,
    email_templates,
  });
};

const fetchProjectTableSuccess = (state, action) => {
  const { count, next, previous, results, projects_info } = action.data;
  return updateObject(state, {
    count,
    next,
    previous,
    loading: false,
    projects: results,
    error: null,
    projects_info,
  });
};

const fetchProjectFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const fetchSingleProjectSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    singleProject: action.data,
    status: action.data.archive_status,
    error: null,
  });
};

const archiveProjectSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    status: action.data.status,
    error: null,
  });
};

const unArchiveProjectSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    status: action.data.status,
  });
};

const favouriteProjectSuccess = (state, action) => {
  const project = state.projects.find(
    (project) => project?.id === action.projectId
  );
  return updateObject(state, {
    loading: false,
    error: null,
    favouriteProjects: [...state.favouriteProjects, project],
  });
};

const unfavouriteProjectSuccess = (state, action) => {
  const newProjects = state.favouriteProjects.filter(
    (project) => project?.id !== action.projectId
  );
  return updateObject(state, {
    loading: false,
    error: null,
    favouriteProjects: newProjects,
  });
};

const addProjectSiteRouteSuccess = (state, action) => {
  const project = state.projects.find(
    (project) => project?.id === action.projectId
  );
  return updateObject(state, {
    siteRoutesProjects: [...state.siteRoutesProjects, project],
    loading: false,
    error: null,
  });
};

const removeProjectSiteRouteSuccess = (state, action) => {
  const newProjects = state.siteRoutesProjects.filter(
    (project) => project?.id !== action.projectId
  );
  return updateObject(state, {
    siteRoutesProjects: newProjects,
    loading: false,
    error: null,
  });
};

const removeAllProjectSiteRouteSuccess = (state, action) => {
  return updateObject(state, {
    siteRoutesProjects: [],
    loading: false,
    error: null,
  });
};

const updateSiteRouteProjectSuccess = (state, action) => {
  const archive_projects = action.payload.project_archives.map((prj) => prj.id);
  const srr_projects = action.payload.project_site_routes.filter(
    (project) => !archive_projects.includes(project.id)
  );
  return updateObject(state, {
    loading: false,
    error: null,
    siteRoutesProjects: srr_projects,
  });
};

const siteRouteNotesRequest = (state, action) => {
  return updateObject(state, {
    loadingNotes: true,
    error: null,
    siteRoutesNotes: {},
  });
};

const siteRouteNotesSuccess = (state, action) => {
  return updateObject(state, {
    loadingNotes: false,
    error: null,
    siteRoutesNotes: action.notes,
  });
};

const siteRouteNotesFail = (state, action) => {
  return updateObject(state, {
    loadingNotes: false,
    error: action.error,
  });
};

const contactLastDateRequest = (state, action) => {
  return updateObject(state, {
    loadingDate: true,
    error: null,
  });
};

const contactLastDateSuccess = (state, action) => {
  return updateObject(state, {
    loadingDate: false,
    error: null,
    lastDate: action.lastDate,
    last_create_activity: action.activity,
  });
};

const contactLastDateFail = (state, action) => {
  return updateObject(state, {
    loadingDate: false,
    error: action.error,
  });
};

const projectContactLastDateRequest = (state, action) => {
  return updateObject(state, {
    loadingProjectDate: true,
    error: null,
  });
};

const projectContactLastDateSuccess = (state, action) => {
  return updateObject(state, {
    loadingProjectDate: false,
    error: null,
    projectLastDate: action.lastDate,
  });
};

const projectAccountLastDate = (state, action) => {
  return updateObject(state, {
    singleProject: {
      ...state.singleProject,
      last_account_date: action.last_account_date,
    }
  });
};


const addContactActivityRequest = (state, action) => {
  return updateObject(state, {
    loadingDate: true,
    error: null,
  });
};

const addContactActivitySuccess = (state, action) => {
  return updateObject(state, {
    loadingDate: false,
    error: null,
    lastDate: action.lastDate,
    last_create_activity: action.activity,
  });
};

const addContactActivityFail = (state, action) => {
  return updateObject(state, {
    loadingDate: false,
    error: action.error,
  });
};

const deleteContactActivityRequest = (state, action) => {
  return updateObject(state, {
    deleting_activity: true,
    last_delete_activity: null,
    error: null,
  });
};

const deleteContactActivitySuccess = (state, action) => {
  return updateObject(state, {
    deleting_activity: false,
    last_delete_activity: action.activity_id,
    error: null,
  });
};

const deleteContactActivityFail = (state, action) => {
  return updateObject(state, {
    deleting_activity: false,
    last_delete_activity: null,
    error: null,
  });
};

const projectContactLastDateFail = (state, action) => {
  return updateObject(state, {
    loadingProjectDate: false,
    error: action.error,
  });
};

const projectNotesRequest = (state, action) => {
  return updateObject(state, {
    loadingNotes: true,
    error: null,
  });
};

const projectNotesSuccess = (state, action) => {
  return updateObject(state, {
    loadingNotes: false,
    error: null,
  });
};

const projectNotesFail = (state, action) => {
  return updateObject(state, {
    loadingNotes: false,
    error: action.error,
  });
};

const updatePricingModal = (state, action) => {
  return updateObject(state, {
    pricingModalShow: action.pricing_modal,
  });
};

const updateFavouriteProjectSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    favouriteProjects: action.projects,
  });
};

const projectActionFailed = (error) => {
  return {
    type: PROJECT_ACTION_FAILED,
    error,
    loading: false,
  };
};

const projectSaveSearchStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const projectSaveSearch = (state) => {
  return updateObject(state, {
    loading: false,
  });
};

const projectDeleteSearchStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const projectDeleteSearch = (state) => {
  return updateObject(state, {
    loading: false,
  });
};

const getEmailTemplateStart = (state) => {
  return updateObject(state, {
    emailTemplate: null,
    emailTemplateError: null,
  });
};

const getEmailTemplateSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    emailTemplate: action.data,
    emailTemplateError: "",
  });
};

const getEmailTemplateFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    emailTemplateError: action.error,
  });
};

const projects = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_START:
      return fetchProjectStart(state, action);
    case FETCH_PROJECTS_SUCCESS:
      return fetchProjectSuccess(state, action);
    case FETCH_PROJECTS_TABLE_SUCCESS:
      return fetchProjectTableSuccess(state, action);
    case FETCH_PROJECTS_FAIL:
      return fetchProjectFail(state, action);
    case FETCH_SINGLE_PROJECT_SUCCESS:
      return fetchSingleProjectSuccess(state, action);
    case ARCHIVE_PROJECT_SUCCESS:
      return archiveProjectSuccess(state, action);
    case UNARCHIVE_PROJECT_SUCCESS:
      return unArchiveProjectSuccess(state, action);
    case FAVOURITE_PROJECT_SUCCESS:
      return favouriteProjectSuccess(state, action);
    case UNFAVOURITE_PROJECT_SUCCESS:
      return unfavouriteProjectSuccess(state, action);
    case UPDATE_FAVOURITE_PROJECT_SUCCESS:
      return updateFavouriteProjectSuccess(state, action);
    case PROJECT_ACTION_FAILED:
      return projectActionFailed(state, action);
    case PROJECT_SAVE_SEARCH_START:
      return projectSaveSearchStart(state, action);
    case PROJECT_SAVE_SEARCH_SUCCESS:
      return projectSaveSearch(state, action);
    case PROJECT_SAVE_SEARCH_FAILED:
      return projectSaveSearch(state, action);
    case PROJECT_DELETE_SEARCH_START:
      return projectDeleteSearchStart(state, action);
    case PROJECT_DELETE_SEARCH_SUCCESS:
      return projectDeleteSearch(state, action);
    case PROJECT_DELETE_SEARCH_FAILED:
      return projectDeleteSearch(state, action);
    case GET_EMAIL_TEMPLATE_START:
      return getEmailTemplateStart(state, action);
    case GET_EMAIL_TEMPLATE_SUCCESS:
      return getEmailTemplateSuccess(state, action);
    case GET_EMAIL_TEMPLATE_FAILED:
      return getEmailTemplateFailed(state, action);
    case PROJECT_SITE_ROUTE_SUCCESS:
      return addProjectSiteRouteSuccess(state, action);
    case PROJECT_REMOVE_SITE_ROUTE_SUCCESS:
      return removeProjectSiteRouteSuccess(state, action);
    case PROJECT_REMOVE_ALL_SITE_ROUTE_SUCCESS:
      return removeAllProjectSiteRouteSuccess(state, action);
    case UPDATE_SITE_ROUTE_PROJECT_SUCCESS:
      return updateSiteRouteProjectSuccess(state, action);
    case SITE_ROUTE_NOTES_REQUEST:
      return siteRouteNotesRequest(state, action);
    case SITE_ROUTE_NOTES_SUCCESS:
      return siteRouteNotesSuccess(state, action);
    case SITE_ROUTE_NOTES_FAILED:
      return siteRouteNotesFail(state, action);
    case UPDATE_PRICING_MODAL:
      return updatePricingModal(state, action);
    case UPDATE_LAST_CONTACT_REQUEST:
      return contactLastDateRequest(state, action);
    case UPDATE_LAST_CONTACT_SUCCESS:
      return contactLastDateSuccess(state, action);
    case UPDATE_LAST_CONTACT_FAILED:
      return contactLastDateFail(state, action);
    case UPDATE_PROJECT_LAST_CONTACT_REQUEST:
      return projectContactLastDateRequest(state, action);
    case UPDATE_PROJECT_LAST_CONTACT_SUCCESS:
      return projectContactLastDateSuccess(state, action);
    case UPDATE_ACCOUNT_LAST_CONTACT:
      return projectAccountLastDate(state, action);
    case UPDATE_PROJECT_LAST_CONTACT_FAILED:
      return projectContactLastDateFail(state, action);
    case UPDATE_PROJECT_NOTES_REQUEST:
      return projectNotesRequest(state, action);
    case UPDATE_PROJECT_NOTES_SUCCESS:
      return projectNotesSuccess(state, action);
    case UPDATE_PROJECT_NOTES_FAILED:
      return projectNotesFail(state, action);
    case ADD_ACTIVITY_REQUEST:
      return addContactActivityRequest(state, action);
    case ADD_ACTIVITY_SUCCESS:
      return addContactActivitySuccess(state, action);
    case ADD_ACTIVITY_FAILED:
      return addContactActivityFail(state, action);
    case REMOVE_ACTIVITY_REQUEST:
      return deleteContactActivityRequest(state, action);
    case REMOVE_ACTIVITY_SUCCESS:
      return deleteContactActivitySuccess(state, action);
    case REMOVE_ACTIVITY_FAILED:
      return deleteContactActivityFail(state, action);
    default:
      return state;
  }
};

const userProjectBoardState = {
  loading: false,
  project: null,
  error: null,
  updating: false,
  updateError: null,
};

export const userProjectBoardDataReducer = (
  state = userProjectBoardState,
  action
) => {
  switch (action.type) {
    case UPDATE_USER_PROJECT_BOARD_DATA_REQUEST:
      return { ...state, updating: true, updateError: null };
    case UPDATE_USER_PROJECT_BOARD_DATA_SUCCESS:
      return {
        ...state,
        updating: false,
        project: {
          ...state.project,
          ...action.payload.data, // Update the specific fields
        },
      };
    case UPDATE_USER_PROJECT_BOARD_DATA_FAILURE:
      return { ...state, updating: false, updateError: action.payload };

    default:
      return state;
  }
};

export default projects;
